<template>
  <header
    class="delay-navigation flex w-full items-center bg-white transition duration-navigation"
    data-cy-main-header
  >
    <app-page-wrapper class="flex items-center gap-6">
      <main-header-navigation-dropdown
        v-if="!noNavigation"
        class="desktop-nav:hidden"
      />

      <nuxt-link :class="{ 'hidden desktop-nav:block': !noNavigation }" to="/">
        <brand-navigation-logo />
      </nuxt-link>

      <main-header-navigation
        v-if="!noNavigation"
        class="hidden desktop-nav:block"
        :items="items"
      />

      <div class="flex flex-auto items-center justify-end gap-2">
        <template v-if="!isAuthenticated">
          <app-button
            class="hidden desktop-nav:block"
            color="dark"
            data-cy-main-header-signin
            rel="nofollow"
            :to="{
              name: 'auth-login',
              hash: '#signin',
              query: {
                redirect: $route.fullPath,
              },
            }"
            variant="tonal"
          >
            {{ $t("authentication.signin_navigation_label") }}
          </app-button>

          <growth-contact-button color="primary" origin="main_navigation">
            <span class="hidden sm:inline">Prendre rendez-vous</span>
            <span class="whitespace-nowrap sm:hidden">Prendre RDV</span>
          </growth-contact-button>
        </template>

        <client-only v-if="isAuthenticated">
          <main-header-user-menu data-cy-user-entry />
        </client-only>
      </div>
    </app-page-wrapper>
  </header>
</template>

<script lang="ts" setup>
import BrandNavigationLogo from "~/business-areas/brand/components/brand-navigation-logo.vue";
import MainHeaderNavigation from "~/layouts/_includes/components/main-header-navigation.vue";
import MainHeaderNavigationDropdown from "~/layouts/_includes/components/main-header-navigation-dropdown.vue";
import MainHeaderUserMenu from "~/layouts/_includes/components/main-header-user-menu.vue";

defineProps<{
  noNavigation?: boolean;
}>();

const { isAuthenticated } = useCurrentUser();

const { items } = useMainHeaderNavigation();
</script>
